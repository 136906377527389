export function getVideoURL(vimeoId: string, embed: boolean = false): string {
  const specialVimeoIds = [
    // prod videos -->
    '1015841265',
    '1015835066',
    '1015839648',
    '1015842940',
    '1015838948',
    '1015845966',
    '1015845561',
    '1015840466',
    '1015844735',
    '1015846308',
    '1015837568',
    '1015842281',
    '1015846721',
    '1015841894',
    // <-- prod videos
    '990559941', // stg video
  ]
  if (specialVimeoIds.includes(vimeoId) && !process.env.NEXT_PUBLIC_APP_URL?.endsWith('.local')) {
    const baseUrl =
      process.env.NEXT_PUBLIC_APP_URL && process.env.NEXT_PUBLIC_APP_URL.startsWith('app.staging')
        ? 'https://s3.us-west-1.amazonaws.com/cdn.v2.staging.makerconnect.com/videos/'
        : 'https://s3.us-west-1.amazonaws.com/cdn.v2.makerconnect.com/videos/'
    return `${baseUrl}${vimeoId}.mp4`
  } else {
    return embed ? `https://player.vimeo.com/video/${vimeoId}` : `https://vimeo.com/${vimeoId}`
  }
}
