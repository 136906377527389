import isEqual from 'lodash/isEqual'
import { useRouter } from 'next/router'
import { createContext, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'

import { userIsConsultant } from 'roles'
import { routes } from 'routes'

import { useUser } from 'providers/UserProvider'
import {
  projectListingAccountIdFilterVar,
  projectListingDateModifiedFilterVar,
  projectListingIsOpenFilterVar,
  projectListingKeywordFilterVar,
  projectListingNameFilterVar,
  projectListingOwnerFilterVar,
  projectListingSharedWithFilterVar,
  projectListingSuborganizationIdFilterVar,
  projectListingValueFilterVar,
  resetProjectListingVars,
} from 'utils/apollo-cache'
import { ALL_FILTER_VALUE } from 'utils/constants'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SearchContext = createContext<ReturnType<typeof useInit>>(null as any)

export type DateModifiedFilter = 'anytime' | '1d' | '1w' | '1m'

export type SearchParams = {
  name: string
  keywords: string
  dateModified: DateModifiedFilter
  valueMin: string
  valueMax: string
  isOpen?: boolean
  accountId: string
  suborganizationId: string
  owner: string | 'anyone'
  sharedWith: string[]
}

const useInit = (isArchived: boolean) => {
  const router = useRouter()
  const { user } = useUser()

  const defaultOrganizationIdValue = userIsConsultant(user)
    ? ALL_FILTER_VALUE
    : (user?.accountId ?? '')

  const defaultParams: SearchParams = useMemo(() => {
    return {
      name: '',
      keywords: '',
      dateModified: 'anytime',
      valueMin: '0',
      valueMax: '0',
      isOpen: false,
      accountId: defaultOrganizationIdValue,
      suborganizationId: '',
      owner: '',
      sharedWith: [],
    }
  }, [defaultOrganizationIdValue])

  const [searchParams, setSearchParams] = useState<SearchParams>(
    router.query
      ? {
          ...defaultParams,
          ...router.query,
          isOpen: router.query.isOpen?.toString().toLowerCase() === 'true', // because query params are always strings we need to convert to boolean
          sharedWith: parseSharedWith(router.query.sharedWith),
        }
      : defaultParams
  )

  // Sync history state changes with search params
  useEffect(() => {
    function fn(newUrl: string) {
      const url = new URL(newUrl, window.location.href).searchParams
      const params = Object.fromEntries(url)
      setSearchParams({
        ...defaultParams,
        ...params,
        isOpen: params.isOpen === 'true', // because query params are always strings we need to convert to boolean
        sharedWith: parseSharedWith(params.sharedWith),
      })
    }
    router.events.on('routeChangeComplete', fn)
    return () => router.events.off('routeChangeComplete', fn)
  }, [defaultParams, router.events, router.query])

  useEffect(() => {
    projectListingNameFilterVar(searchParams.name)
  }, [searchParams.name])

  useEffect(() => {
    projectListingIsOpenFilterVar(searchParams.isOpen)
  }, [searchParams.isOpen])

  useEffect(() => {
    projectListingAccountIdFilterVar(searchParams.accountId)
  }, [searchParams.accountId])

  useEffect(() => {
    projectListingSuborganizationIdFilterVar(searchParams.suborganizationId)
  }, [searchParams.suborganizationId])

  useEffect(() => {
    projectListingOwnerFilterVar(searchParams.owner || 'anyone')
  }, [searchParams.owner])

  useEffect(() => {
    projectListingKeywordFilterVar(searchParams.keywords)
  }, [searchParams.keywords])

  useEffect(() => {
    projectListingDateModifiedFilterVar(searchParams.dateModified || 'anytime')
  }, [searchParams.dateModified])

  useEffect(() => {
    projectListingValueFilterVar([searchParams.valueMin, searchParams.valueMax])
  }, [searchParams.valueMin, searchParams.valueMax])

  useEffect(() => {
    const list = parseSharedWith(searchParams.sharedWith)
    projectListingSharedWithFilterVar({
      field: 'sharedWithUsers',
      operator: 'isAnyOf',
      value: list,
    })
  }, [searchParams.sharedWith])

  return {
    searchParams,
    hasSearchQuery: !isEqual(searchParams, defaultParams),
    resetSearch: () => {
      setSearchParams(defaultParams)
      resetProjectListingVars()
      router.push(isArchived ? routes.npt.archivedProjects : routes.npt.projects)
    },
    triggerSearch: (params?: SetStateAction<SearchParams>) => {
      if (params) {
        setSearchParams(params)
      }
      // Convert search params to url object
      if (typeof params !== 'function') {
        const modifiedParams = { ...params }
        if (!params?.isOpen) {
          delete modifiedParams.isOpen
        }
        const searchParams = {
          ...Object.fromEntries(
            Object.entries(modifiedParams).map(([key, value]) => [
              key,
              Array.isArray(value) ? value.join(',') : String(value),
            ])
          ),
        }
        const search = new URLSearchParams(searchParams).toString()
        router.push({
          pathname: isArchived ? routes.npt.archivedProjects : routes.npt.projects,
          search,
        })
      }
    },
  }
}

export function SearchProvider({
  children,
  isArchived = false,
}: {
  children?: React.ReactNode
  isArchived?: boolean
}) {
  return <SearchContext.Provider value={useInit(isArchived)}>{children}</SearchContext.Provider>
}

export const useSearch = () => {
  return useContext(SearchContext)
}

const parseSharedWith = (sharedWith: unknown): string[] => {
  if (typeof sharedWith === 'string') {
    // Remove empty strings from list
    return sharedWith?.split(',').filter((id) => !!id)
  }
  if (Array.isArray(sharedWith)) {
    return sharedWith
  }
  return []
}
