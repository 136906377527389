import { Button, List, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { useGetEditRightsRequestsQuery } from 'graphql/getEditRightsRequests.gen'
import { useRespondToEditRightsRequestMutation } from 'graphql/respondToEditRightsRequest.gen'
import { EditRightsRequestStatus } from 'graphql/types'

import GenericDialog from 'components/GenericDialog'
import { useToast } from 'components/toast/ToastProvider'
import { formatSignInDate } from 'utils/date-time'

type Props = {
  open: boolean
  closeHandler: () => void
  projectId: string
}

export default function EditRightsRequestsDialog({ open, closeHandler, projectId }: Props) {
  const router = useRouter()
  const { addToast } = useToast()
  const { data, refetch } = useGetEditRightsRequestsQuery({
    fetchPolicy: 'cache-and-network',
  })
  const [respondToRequest] = useRespondToEditRightsRequestMutation()

  // Handle URL parameter for auto-opening
  useEffect(() => {
    if (router.query.editRightsRequests === 'true') {
      router.replace({ query: { ...router.query, editRightsRequests: undefined } }, undefined, {
        shallow: true,
      })
    }
  }, [router])

  const handleResponse = async (requestId: string, isApproved: boolean) => {
    try {
      await respondToRequest({
        variables: {
          input: {
            requestId,
            isApproved,
          },
        },
      })
      addToast({
        message: `Edit rights request ${isApproved ? 'approved' : 'rejected'} successfully`,
        color: 'success',
      })
      refetch()
    } catch (error) {
      if (!(error instanceof Error)) {
        throw error
      }
      addToast({
        message: 'Failed to respond to edit rights request',
        color: 'error',
      })
    }
  }

  const requests = data?.editRightsRequests?.nodes

  // Filter pending requests for the current project
  const currentProjectPendingRequests = requests?.filter(
    (request) =>
      request?.projectId === projectId && request?.status === EditRightsRequestStatus.Pending
  )

  return (
    <GenericDialog
      open={open}
      closeHandler={closeHandler}
      disableSubmit
      title="Edit Rights Requests"
      subtitle="Pending Requests from other users for this project">
      {currentProjectPendingRequests?.length === 0 ? (
        <Typography>No pending edit rights requests</Typography>
      ) : (
        <List>
          {currentProjectPendingRequests?.map((request) => (
            <ListItem key={request.id} divider>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%">
                <ListItemText
                  primary={`${request.requester?.fullname} requested edit rights`}
                  secondary={formatSignInDate(request.requestedAt)}
                />
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => handleResponse(request.id, true)}>
                    Approve
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => handleResponse(request.id, false)}>
                    Reject
                  </Button>
                </Stack>
              </Stack>
            </ListItem>
          ))}
        </List>
      )}
    </GenericDialog>
  )
}
