export enum ObjectiveFormNames {
  ALL = 'project-objectives',
  // standard form names
  OPPORTUNITY = 'opportunity',
  DYNAMICS = 'dynamics',
  YOUR_OBJECTIVES = 'smart',
  THEIR_OBJECTIVES = 'their',
  INTERNAL_RESTRICTIONS = 'restrictions_int',
  EXTERNAL_RESTRICTIONS = 'restrictions_ext',
  // customer_ask form names
  OPPORTUNITY_STATEMENT = 'opportunity_statement',
  BACKGROUND = 'background',
  KEY_CONSIDERATIONS = 'key_considerations',
  RECOMMENDATION = 'recommendation',
  APPENDIX = 'appendix',
}

export enum PowerFormNames {
  ENHANCE_POWER = 'enhance_power',
  ENHANCE_HOW = 'enhance_how',
  CHANGE_POWER = 'change_power',
  CHANGE_HOW = 'change_how',
  DOWNGRADE_POWER = 'downgrade_power',
  DOWNGRADE_HOW = 'downgrade_how',
  EXPLOIT_POWER = 'exploit_power',
  EXPLOIT_HOW = 'exploit_how',
}

export enum YourObjectivesInputNames {
  SPECIFIC = 'specific',
  MEASURABLE = 'measurable',
  ACHIEVABLE = 'achievable',
  RELEVANT = 'relevant',
  TIME_BOUND = 'time-bound',
}

export enum TheirObjectivesInputNames {
  OBJECTIVE = 'objective',
}

export enum YourQuestionsInputNames {
  Question = 'question',
  Who = 'who',
  Objective = 'objective',
  Information = 'information',
  Assumption = 'assumption',
  How = 'how',
  What = 'what',
}

export enum TheirQuestionsInputNames {
  What = 'what',
  How = 'how',
}

export enum CustomerAskAppendixInputNames {
  APPENDIX = 'appendix',
}

export const FORMS_DEBOUNCE_TIME = 1000

export const ALL_FILTER_VALUE = 'all'

// MIME types ref: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const PDF_MIME_TYPE = {
  'application/pdf': ['.pdf'],
}

// Vimeo Auto Retry
export const MAX_VIMEO_AUTO_RETRIES = 2
export const VIMEO_LOADING_TIMEOUT = 10000
