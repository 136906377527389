import * as Types from '../types';

import { gql } from '@apollo/client';
import { AccountFieldsFragmentDoc } from './AccountFields.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountsListQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.AccountFilter>;
}>;


export type AccountsListQuery = { __typename?: 'Query', accounts: { __typename?: 'AccountsConnection', nodes: Array<{ __typename?: 'Account', isArchived: boolean, nodeId: string, id: string, name: string | null, industryId: string | null, websiteUrl: string | null, createdAt: string, countryRegionId: string | null, city: string | null, state: string | null, zipCode: string | null, address1: string | null, address2: string | null, timezone: string | null, phone: string | null, contactName: string | null, contactTitle: string | null, contactEmail: string | null, adminContactMessage: string | null, isNpt: boolean, isTeamsEnabled: boolean, isLv1: boolean, isLms: boolean, isIvl: boolean | null, isCsmt: boolean | null, hasSso: boolean | null, ssoIntegrationTest: boolean | null, idpName: string | null, parentAccountId: string | null, users: { __typename?: 'UsersConnection', totalCount: number }, teamsList: Array<{ __typename?: 'Team', id: string, name: string, accountId: string, parentTeamId: string | null, account: { __typename?: 'Account', isTeamsEnabled: boolean } | null, hierarchyPath: Array<{ __typename?: 'TeamHierarchyInfo', id: string | null, name: string | null } | null>, teamMembersList: Array<{ __typename?: 'TeamMember', id: string, isLead: boolean, userId: string }>, projects: { __typename?: 'ProjectsConnection', totalCount: number } }>, suborganizations: { __typename?: 'SuborganizationsConnection', nodes: Array<{ __typename?: 'Suborganization', id: string, name: string, accountId: string, createdAt: string, users: { __typename?: 'UsersConnection', totalCount: number } }> } }> } | null };


export const AccountsListDocument = gql`
    query AccountsList($filter: AccountFilter) {
  accounts(filter: $filter, orderBy: NAME_ASC) {
    nodes {
      ...AccountFields
      isArchived
      users {
        totalCount
      }
    }
  }
}
    ${AccountFieldsFragmentDoc}`;

/**
 * __useAccountsListQuery__
 *
 * To run a query within a React component, call `useAccountsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAccountsListQuery(baseOptions?: Apollo.QueryHookOptions<AccountsListQuery, AccountsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsListQuery, AccountsListQueryVariables>(AccountsListDocument, options);
      }
export function useAccountsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsListQuery, AccountsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsListQuery, AccountsListQueryVariables>(AccountsListDocument, options);
        }
export function useAccountsListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountsListQuery, AccountsListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountsListQuery, AccountsListQueryVariables>(AccountsListDocument, options);
        }
export type AccountsListQueryHookResult = ReturnType<typeof useAccountsListQuery>;
export type AccountsListLazyQueryHookResult = ReturnType<typeof useAccountsListLazyQuery>;
export type AccountsListSuspenseQueryHookResult = ReturnType<typeof useAccountsListSuspenseQuery>;
export type AccountsListQueryResult = Apollo.QueryResult<AccountsListQuery, AccountsListQueryVariables>;