import * as Types from '../types';

import { gql } from '@apollo/client';
import { ShortUserFieldsFragmentDoc } from '../user/ShortUserFields.gen';
export type MembershipFieldsFragment = { __typename?: 'ProjectMembership', id: string, isOwner: boolean, isEditor: boolean, user: { __typename?: 'User', id: string, accountId: string | null, role: string, email: string, avatarUrl: string | null, fullname: string, isTeamsAdmin: boolean } | null };

export const MembershipFieldsFragmentDoc = gql`
    fragment MembershipFields on ProjectMembership {
  id
  isOwner
  isEditor
  user {
    ...ShortUserFields
  }
}
    ${ShortUserFieldsFragmentDoc}`;