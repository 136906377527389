import { differenceBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

import { useGetEditRightsRequestsQuery } from 'graphql/getEditRightsRequests.gen'
import { useSharedProjectsQuery } from 'graphql/notifications/SharedProjects.gen'
import { EditRightsRequestStatus } from 'graphql/types'

import { useToast } from 'components/toast/ToastProvider'
import useUserEvent, { ChangeEvent } from 'hooks/useUserEvent'
import { useUser } from 'providers/UserProvider'

import NotificationPopover from './NotificationPopover'

export default function NotificationPanel() {
  const { userId } = useUser()
  const { addToast } = useToast()
  const [lastCount, setLastCount] = useState<number>(0)
  const { data, previousData, refetch } = useSharedProjectsQuery({
    variables: { userId: userId ?? '' },
    fetchPolicy: 'network-only',
  })
  const { data: editRightsRequestsData } = useGetEditRightsRequestsQuery({
    fetchPolicy: 'cache-and-network',
  })

  // Filter memberships where either:
  // 1. The user has never accessed the project, or
  // 2. Their last access was before their membership was created
  const filteredMemberships = useMemo(() => {
    if (!data?.projectMemberships?.nodes) return []

    return data.projectMemberships.nodes.filter((membership) => {
      const accessLogs = membership.project?.projectAccessLogs?.nodes || []

      // Case 1: No access logs
      if (accessLogs.length === 0) return true

      // Case 2: Last access was before membership creation
      const lastAccess = accessLogs.reduce((latest, log) => {
        return log.accessAt > latest ? log.accessAt : latest
      }, accessLogs[0]?.accessAt || '')

      const membershipCreated = new Date(membership.createdAt)
      const lastAccessDate = new Date(lastAccess)

      return lastAccessDate < membershipCreated
    })
  }, [data?.projectMemberships?.nodes])

  const filteredEditRightsRequests = [
    ...(editRightsRequestsData?.editRightsRequests?.nodes ?? []),
  ].filter((request) => {
    // Always show pending requests
    if (request.status === EditRightsRequestStatus.Pending) return true

    // For approved requests, only show if the project is still in shared projects
    // (meaning user hasn't accessed it yet)
    if (request.status === EditRightsRequestStatus.Approved) {
      return filteredMemberships.some((membership) => membership.projectId === request.projectId)
    }

    // For rejected requests, only show if the last user access was before the request was rejected
    if (request.status === EditRightsRequestStatus.Rejected) {
      const userAccessLogs =
        request.project?.projectAccessLogs?.nodes.filter((log) => log.userId === userId) || []

      // If no access logs, always show the rejected request
      if (userAccessLogs.length === 0) return true

      // Get the most recent access date
      const lastAccess = userAccessLogs.reduce((latest, log) => {
        return log.accessAt > latest ? log.accessAt : latest
      }, userAccessLogs[0]?.accessAt || '')

      const lastAccessDate = new Date(lastAccess)
      const requestRejectedAt = new Date(request.respondedAt || '')
      return lastAccessDate < requestRejectedAt
    }

    return false
  })

  const hasActiveProjects = filteredMemberships.some((project) => project.isArchived === false)
  useUserEvent({ refetch }, ChangeEvent.UserMembership)

  useEffect(() => {
    if (!filteredMemberships) return
    setLastCount(filteredMemberships.length ?? 0)
    // Trigger notification on the second fetch, not initially
    if (
      previousData !== undefined &&
      filteredMemberships &&
      previousData.projectMemberships?.nodes &&
      previousData.projectMemberships.nodes[0]?.userId === userId // handles case where we had previous data from other user (login as)
    ) {
      // More projects than previous => access to a project has been granted
      if (filteredMemberships.length > lastCount) {
        const sharedProjects = differenceBy(
          filteredMemberships,
          previousData.projectMemberships?.nodes,
          (pm) => {
            return pm.id
          }
        )
        if (!hasActiveProjects) return // don't show notifications for archived projects
        let message = 'A project has been shared with you!'
        if (sharedProjects.length === 1) {
          message = `"${sharedProjects[0].project?.name}" has been shared with you!`
        }

        addToast({
          color: 'info',
          message,
          duration: 7000, // A bit more time, because the message is a bit longer.
        })
        // Less projects than previous => access to a project has been revoked
      } else if (filteredMemberships.length < lastCount) {
        const revokedProjects = differenceBy(
          previousData.projectMemberships?.nodes,
          filteredMemberships,
          (pm) => {
            return pm.id
          }
        )
        if (!hasActiveProjects) return // don't show notifications for archived projects
        let message = 'A project access has been revoked from you!'
        if (revokedProjects.length === 1) {
          message = `"${revokedProjects[0].project?.name}" access has been revoked from you!`
        }

        addToast({
          color: 'info',
          message,
          duration: 7000, // A bit more time, because the message is a bit longer.
        })
      }
    }
  }, [previousData, addToast, filteredMemberships, lastCount, hasActiveProjects, userId])

  return (
    <NotificationPopover
      items={filteredMemberships ?? []}
      editRightsRequests={filteredEditRightsRequests ?? []}
    />
  )
}
