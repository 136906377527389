import * as Types from './types';

import { gql } from '@apollo/client';
import { EditRightsRequestFieldsFragmentDoc } from './EditRightsRequestFields.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEditRightsRequestsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.EditRightsRequestFilter>;
}>;


export type GetEditRightsRequestsQuery = { __typename?: 'Query', editRightsRequests: { __typename?: 'EditRightsRequestsConnection', nodes: Array<{ __typename?: 'EditRightsRequest', id: string, projectId: string, requesterId: string, status: Types.EditRightsRequestStatus, requestedAt: string, respondedAt: string | null, project: { __typename?: 'Project', name: string, userIsOwner: boolean | null, projectAccessLogs: { __typename?: 'ProjectAccessLogsConnection', nodes: Array<{ __typename?: 'ProjectAccessLog', accessAt: string, userId: string }> } } | null, requester: { __typename?: 'User', fullname: string } | null }> } | null };


export const GetEditRightsRequestsDocument = gql`
    query GetEditRightsRequests($filter: EditRightsRequestFilter) {
  editRightsRequests(filter: $filter) {
    nodes {
      ...EditRightsRequestFields
    }
  }
}
    ${EditRightsRequestFieldsFragmentDoc}`;

/**
 * __useGetEditRightsRequestsQuery__
 *
 * To run a query within a React component, call `useGetEditRightsRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditRightsRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditRightsRequestsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetEditRightsRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetEditRightsRequestsQuery, GetEditRightsRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEditRightsRequestsQuery, GetEditRightsRequestsQueryVariables>(GetEditRightsRequestsDocument, options);
      }
export function useGetEditRightsRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEditRightsRequestsQuery, GetEditRightsRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEditRightsRequestsQuery, GetEditRightsRequestsQueryVariables>(GetEditRightsRequestsDocument, options);
        }
export function useGetEditRightsRequestsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEditRightsRequestsQuery, GetEditRightsRequestsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEditRightsRequestsQuery, GetEditRightsRequestsQueryVariables>(GetEditRightsRequestsDocument, options);
        }
export type GetEditRightsRequestsQueryHookResult = ReturnType<typeof useGetEditRightsRequestsQuery>;
export type GetEditRightsRequestsLazyQueryHookResult = ReturnType<typeof useGetEditRightsRequestsLazyQuery>;
export type GetEditRightsRequestsSuspenseQueryHookResult = ReturnType<typeof useGetEditRightsRequestsSuspenseQuery>;
export type GetEditRightsRequestsQueryResult = Apollo.QueryResult<GetEditRightsRequestsQuery, GetEditRightsRequestsQueryVariables>;