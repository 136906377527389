import { Collapse, List, Typography } from '@mui/material'
import { TransitionGroup } from 'react-transition-group'
import { NodesType } from 'ts-utils'

import { EditRightsRequestFieldsFragment } from 'graphql/EditRightsRequestFields.gen'
import { SharedProjectsQuery } from 'graphql/notifications/SharedProjects.gen'

import NotificationItem from './NotificationItem'
import RequestNotificationItem from './RequestNotificationItem'

export default function NotificationsList({
  items,
  editRightsRequests,
}: {
  items: NodesType<SharedProjectsQuery['projectMemberships']>[]
  editRightsRequests: EditRightsRequestFieldsFragment[]
}) {
  if (items.length === 0 && editRightsRequests.length === 0) {
    return (
      <Typography variant="body1" p={2} color="primary" fontWeight={300}>
        No unread notifications
      </Typography>
    )
  }

  return (
    <List>
      <TransitionGroup>
        {editRightsRequests.map((item, index) => (
          <Collapse key={index}>
            <RequestNotificationItem {...item} />
          </Collapse>
        ))}
        {items.map((item, index) => (
          <Collapse key={index}>
            <NotificationItem {...item} />
          </Collapse>
        ))}
      </TransitionGroup>
    </List>
  )
}
