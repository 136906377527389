import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SharedProjectsQueryVariables = Types.Exact<{
  userId: Types.Scalars['UUID']['input'];
  count?: Types.Scalars['Int']['input'];
}>;


export type SharedProjectsQuery = { __typename?: 'Query', projectMemberships: { __typename?: 'ProjectMembershipsConnection', nodes: Array<{ __typename?: 'ProjectMembership', id: string, userId: string, createdAt: string, isArchived: boolean, projectId: string, createdByUser: { __typename?: 'User', id: string, fullname: string } | null, project: { __typename?: 'Project', id: string, name: string, projectAccessLogs: { __typename?: 'ProjectAccessLogsConnection', nodes: Array<{ __typename?: 'ProjectAccessLog', accessAt: string }> } } | null }> } | null };


export const SharedProjectsDocument = gql`
    query SharedProjects($userId: UUID!, $count: Int! = 15) {
  projectMemberships(
    filter: {userId: {equalTo: $userId}, createdByUserId: {notEqualTo: $userId}}
    orderBy: CREATED_AT_DESC
    first: $count
  ) {
    nodes {
      id
      userId
      createdAt
      isArchived
      createdByUser {
        id
        fullname
      }
      projectId
      project {
        id
        name
        projectAccessLogs(filter: {userId: {equalTo: $userId}}) {
          nodes {
            accessAt
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSharedProjectsQuery__
 *
 * To run a query within a React component, call `useSharedProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedProjectsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useSharedProjectsQuery(baseOptions: Apollo.QueryHookOptions<SharedProjectsQuery, SharedProjectsQueryVariables> & ({ variables: SharedProjectsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SharedProjectsQuery, SharedProjectsQueryVariables>(SharedProjectsDocument, options);
      }
export function useSharedProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SharedProjectsQuery, SharedProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SharedProjectsQuery, SharedProjectsQueryVariables>(SharedProjectsDocument, options);
        }
export function useSharedProjectsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SharedProjectsQuery, SharedProjectsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SharedProjectsQuery, SharedProjectsQueryVariables>(SharedProjectsDocument, options);
        }
export type SharedProjectsQueryHookResult = ReturnType<typeof useSharedProjectsQuery>;
export type SharedProjectsLazyQueryHookResult = ReturnType<typeof useSharedProjectsLazyQuery>;
export type SharedProjectsSuspenseQueryHookResult = ReturnType<typeof useSharedProjectsSuspenseQuery>;
export type SharedProjectsQueryResult = Apollo.QueryResult<SharedProjectsQuery, SharedProjectsQueryVariables>;