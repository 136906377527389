import { canEdit, isEditor, isOwner, isViewer } from 'memberships'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react'

import { userIsOrgAdminOrConsultant } from 'roles'
import { routes } from 'routes'

import { useProjectDataQuery } from 'graphql/queries/project/ProjectData.gen'
import { ProjectMembershipType } from 'graphql/types'

import EditRightsRequestsDialog from 'components/dialogs/EditRightsRequestsDialog'
import Preloader from 'components/Preloader'
import { isProjectTeamLead } from 'components/settings/organizations/teams/utils'
import { useToast } from 'components/toast/ToastProvider'
import useConfirm from 'hooks/useConfirm'
import { useUser } from 'providers/UserProvider'
import { RoomProvider } from 'utils/liveblocks.config'

export type ProjectContextType = { projectId: string }

const {
  publicRuntimeConfig: { env },
} = getConfig()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProjectContext = createContext<ReturnType<typeof useInit>>(null as any)

const useInit = ({ projectId }: ProjectContextType) => {
  const router = useRouter()
  const { user } = useUser()
  const { addToast } = useToast()
  const { data, refetch } = useProjectDataQuery({ variables: { projectId } })

  const userMembershipType = useMemo(() => {
    // no access to project
    if (!user || !data?.project) return

    const myMembership = data?.project?.projectMemberships?.nodes.find(
      (membership) => membership.user?.id === user?.id
    )

    // if the user is member of the project, return the membership type
    if (myMembership?.isOwner) {
      return ProjectMembershipType.Owner
    } else if (myMembership?.isEditor) {
      return ProjectMembershipType.Editor
    }

    // org admins should be able to view projects in their organization
    if (userIsOrgAdminOrConsultant(user) && !myMembership) {
      return ProjectMembershipType.Viewer
    }
    // Team Leads and Teams Admins should be able to view projects in their team
    if (data?.project?.team) {
      const isTeamLead = isProjectTeamLead(user, data?.project?.team)
      if (isTeamLead || user?.isTeamsAdmin) {
        return ProjectMembershipType.Viewer
      }
    }

    return
  }, [data?.project, user])

  useEffect(() => {
    if (data?.project && data.project.isDeleted) {
      router.push(routes.npt.projects)
      addToast({
        color: 'error',
        message: 'This project has been deleted!',
      })
    }
    if (data?.project && data.project.isArchived) {
      router.push(routes.npt.archivedProjects)
      addToast({
        color: 'error',
        message: 'This project has been archived!',
      })
    }
  }, [data?.project, router, addToast])

  useEffect(() => {
    if (data && !userMembershipType) {
      router.push(routes.npt.projects)
      addToast({
        color: 'error',
        message: `You don't have access to this project!`,
      })
    }
  }, [data, router, userMembershipType, addToast])

  const userMembership = useMemo(() => {
    return {
      type: userMembershipType,
      isOwner: isOwner(userMembershipType),
      isViewer: isViewer(userMembershipType),
      isEditor: isEditor(userMembershipType),
      canEdit: canEdit(userMembershipType),
    }
  }, [userMembershipType])

  return {
    projectId,
    projectAccountId: data?.project?.accountId ?? undefined,
    projectIsOpen: data?.project?.isOpen ?? undefined,
    project: data?.project ?? undefined,
    userMembership,
    refetch,
  }
}

export const ProjectProvider = ({ projectId, children }: PropsWithChildren<ProjectContextType>) => {
  const router = useRouter()
  const projectData = useInit({ projectId })
  const confirm = useConfirm()
  const initialPresence = useMemo(
    () => ({ pageId: router.route, cursor: null, focusedId: null }),
    [router.route]
  )
  const shouldConnect = useMemo(
    () => projectData.project?.isShared || false,
    [projectData.project?.isShared]
  )

  // Handle edit rights requests dialog
  // Used under the Teams functionality
  useEffect(() => {
    if (router.query.editRightsRequests === 'true') {
      confirm.openHandler()
      // Remove the query parameter without triggering a navigation
      router.replace({ query: { ...router.query, editRightsRequests: undefined } }, undefined, {
        shallow: true,
      })
    }
  }, [confirm, router, router.query.editRightsRequests])

  if (!projectData.userMembership.type) {
    return <Preloader fullscreen />
  }
  return (
    <ProjectContext.Provider value={projectData}>
      <RoomProvider
        id={`${env}-${projectId}`}
        key={`${env}-${projectId}-${shouldConnect}`}
        initialPresence={initialPresence}
        shouldInitiallyConnect={shouldConnect}>
        {children}
      </RoomProvider>
      <EditRightsRequestsDialog
        open={confirm.open}
        closeHandler={confirm.closeHandler}
        projectId={projectId}
      />
    </ProjectContext.Provider>
  )
}

export const useProject = () => {
  return useContext(ProjectContext)
}
