import * as Types from '../types';

import { gql } from '@apollo/client';
export type SuborganizationFieldsFragment = { __typename?: 'Suborganization', id: string, name: string, accountId: string, createdAt: string, users: { __typename?: 'UsersConnection', totalCount: number } };

export const SuborganizationFieldsFragmentDoc = gql`
    fragment SuborganizationFields on Suborganization {
  id
  name
  accountId
  createdAt
  users {
    totalCount
  }
}
    `;