import * as Types from '../../types';

import { gql } from '@apollo/client';
import { MembershipFieldsFragmentDoc } from '../../membership/MembershipFields.gen';
import { TeamFieldsFragmentDoc } from '../../account/TeamFields.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectDataQueryVariables = Types.Exact<{
  projectId: Types.Scalars['UUID']['input'];
}>;


export type ProjectDataQuery = { __typename?: 'Query', project: { __typename?: 'Project', id: string, name: string, value: string | null, parentId: string | null, folderId: string | null, fileType: string, objectivesType: Types.ObjectivesType, accountId: string, isOpen: boolean | null, isActive: boolean | null, isShared: boolean | null, isArchived: boolean | null, isDeleted: boolean, projectMemberships: { __typename?: 'ProjectMembershipsConnection', nodes: Array<{ __typename?: 'ProjectMembership', id: string, isOwner: boolean, isEditor: boolean, user: { __typename?: 'User', id: string, accountId: string | null, role: string, email: string, avatarUrl: string | null, fullname: string, isTeamsAdmin: boolean } | null }> }, team: { __typename?: 'Team', id: string, name: string, accountId: string, parentTeamId: string | null, account: { __typename?: 'Account', isTeamsEnabled: boolean } | null, hierarchyPath: Array<{ __typename?: 'TeamHierarchyInfo', id: string | null, name: string | null } | null>, teamMembersList: Array<{ __typename?: 'TeamMember', id: string, isLead: boolean, userId: string }>, projects: { __typename?: 'ProjectsConnection', totalCount: number } } | null } | null };


export const ProjectDataDocument = gql`
    query ProjectData($projectId: UUID!) {
  project(id: $projectId) {
    id
    name
    value
    parentId
    folderId
    fileType
    objectivesType
    accountId
    isOpen
    isActive
    isShared
    isArchived
    isDeleted
    projectMemberships(orderBy: [IS_OWNER_DESC]) {
      nodes {
        ...MembershipFields
      }
    }
    team {
      ...TeamFields
    }
  }
}
    ${MembershipFieldsFragmentDoc}
${TeamFieldsFragmentDoc}`;

/**
 * __useProjectDataQuery__
 *
 * To run a query within a React component, call `useProjectDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDataQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectDataQuery(baseOptions: Apollo.QueryHookOptions<ProjectDataQuery, ProjectDataQueryVariables> & ({ variables: ProjectDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectDataQuery, ProjectDataQueryVariables>(ProjectDataDocument, options);
      }
export function useProjectDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectDataQuery, ProjectDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectDataQuery, ProjectDataQueryVariables>(ProjectDataDocument, options);
        }
export function useProjectDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProjectDataQuery, ProjectDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectDataQuery, ProjectDataQueryVariables>(ProjectDataDocument, options);
        }
export type ProjectDataQueryHookResult = ReturnType<typeof useProjectDataQuery>;
export type ProjectDataLazyQueryHookResult = ReturnType<typeof useProjectDataLazyQuery>;
export type ProjectDataSuspenseQueryHookResult = ReturnType<typeof useProjectDataSuspenseQuery>;
export type ProjectDataQueryResult = Apollo.QueryResult<ProjectDataQuery, ProjectDataQueryVariables>;