import * as Types from '../types';

import { gql } from '@apollo/client';
import { TeamFieldsFragmentDoc } from './TeamFields.gen';
import { SuborganizationFieldsFragmentDoc } from '../suborganization/SuborganizationFields.gen';
export type AccountFieldsFragment = { __typename?: 'Account', nodeId: string, id: string, name: string | null, industryId: string | null, websiteUrl: string | null, createdAt: string, countryRegionId: string | null, city: string | null, state: string | null, zipCode: string | null, address1: string | null, address2: string | null, timezone: string | null, phone: string | null, contactName: string | null, contactTitle: string | null, contactEmail: string | null, adminContactMessage: string | null, isNpt: boolean, isTeamsEnabled: boolean, isLv1: boolean, isLms: boolean, isIvl: boolean | null, isCsmt: boolean | null, hasSso: boolean | null, ssoIntegrationTest: boolean | null, idpName: string | null, parentAccountId: string | null, teamsList: Array<{ __typename?: 'Team', id: string, name: string, accountId: string, parentTeamId: string | null, account: { __typename?: 'Account', isTeamsEnabled: boolean } | null, hierarchyPath: Array<{ __typename?: 'TeamHierarchyInfo', id: string | null, name: string | null } | null>, teamMembersList: Array<{ __typename?: 'TeamMember', id: string, isLead: boolean, userId: string }>, projects: { __typename?: 'ProjectsConnection', totalCount: number } }>, suborganizations: { __typename?: 'SuborganizationsConnection', nodes: Array<{ __typename?: 'Suborganization', id: string, name: string, accountId: string, createdAt: string, users: { __typename?: 'UsersConnection', totalCount: number } }> } };

export const AccountFieldsFragmentDoc = gql`
    fragment AccountFields on Account {
  nodeId
  id
  name
  industryId
  websiteUrl
  createdAt
  countryRegionId
  city
  state
  zipCode
  address1
  address2
  timezone
  phone
  contactName
  contactTitle
  contactEmail
  adminContactMessage
  isNpt
  isTeamsEnabled
  isLv1
  isLms
  isIvl
  isCsmt
  hasSso
  ssoIntegrationTest
  idpName
  parentAccountId
  teamsList(orderBy: NAME_ASC) {
    ...TeamFields
  }
  suborganizations(orderBy: NAME_ASC) {
    nodes {
      ...SuborganizationFields
    }
  }
}
    ${TeamFieldsFragmentDoc}
${SuborganizationFieldsFragmentDoc}`;