import {
  ListItem,
  ListItemAvatar,
  listItemAvatarClasses,
  ListItemButton,
  ListItemText,
  Link as MuiLink,
} from '@mui/material'
import NextLink from 'next/link'

import { routes } from 'routes'

import { EditRightsRequestFieldsFragment } from 'graphql/EditRightsRequestFields.gen'
import { EditRightsRequestStatus } from 'graphql/types'

import ProjectIcon from 'assets/ProjectIcon'
import { useUser } from 'providers/UserProvider'
import { formatSignInDate } from 'utils/date-time'

export default function RequestNotificationItem({
  id,
  projectId,
  project,
  status,
  requesterId,
  requester,
  requestedAt,
  respondedAt,
}: EditRightsRequestFieldsFragment) {
  const { user } = useUser()
  const isRequester = user?.id === requesterId
  const canApprove = !isRequester && status === EditRightsRequestStatus.Pending

  const getMessage = () => {
    switch (status) {
      case EditRightsRequestStatus.Pending:
        return isRequester
          ? {
              primary: `[Pending] Edit Rights Request for ${project?.name}`,
              secondary: formatSignInDate(requestedAt),
            }
          : {
              primary: `[Requested] Edit Rights from ${requester?.fullname} for ${project?.name}`,
              secondary: formatSignInDate(requestedAt),
            }
      case EditRightsRequestStatus.Approved:
        return isRequester
          ? {
              primary: `[Approved] Edit Rights Request for ${project?.name}`,
              secondary: respondedAt ? formatSignInDate(respondedAt) : '',
            }
          : {
              primary: `You approved Edit Rights Request for ${project?.name}`,
              secondary: respondedAt ? formatSignInDate(respondedAt) : '',
            }
      case EditRightsRequestStatus.Rejected:
        return isRequester
          ? {
              primary: `[Rejected] Edit Rights Request for ${project?.name}`,
              secondary: respondedAt ? formatSignInDate(respondedAt) : '',
            }
          : {
              primary: `You rejected Edit Rights Request for ${project?.name}`,
              secondary: respondedAt ? formatSignInDate(respondedAt) : '',
            }
      default:
        return {
          primary: `Edit Rights Request for ${project?.name}`,
          secondary: formatSignInDate(requestedAt),
        }
    }
  }

  const getProjectUrl = () => {
    const baseUrl = routes.npt.projectLinkById(projectId)
    return canApprove ? `${baseUrl}?editRightsRequests=true` : baseUrl
  }

  const notificationMessage = getMessage()

  return (
    <ListItem id={`notification-${id}`} disablePadding>
      <MuiLink
        component={NextLink}
        href={getProjectUrl()}
        sx={{ width: '100%' }}
        underline="none"
        passHref>
        <ListItemButton>
          <ListItemAvatar sx={{ [`&.${listItemAvatarClasses.root}`]: { minWidth: '2rem' } }}>
            <ProjectIcon sx={{ width: '18px', height: '18px' }} />
          </ListItemAvatar>
          <ListItemText
            primary={notificationMessage.primary}
            secondary={notificationMessage.secondary}
          />
        </ListItemButton>
      </MuiLink>
    </ListItem>
  )
}
