import { GridComparatorFn } from '@mui/x-data-grid'
import { NodesType } from 'ts-utils'

import { userIsConsultantOnly, userIsOrgAdminOrConsultant, userIsSuperAdmin, UserRole } from 'roles'

import { TeamFieldsFragment } from 'graphql/account/TeamFields.gen'
import { AccountUsersQuery } from 'graphql/user/AccountUsers.gen'
import { CurrentUserQuery } from 'graphql/user/CurrentUser.gen'
import { useGetConsultantOrganizationAccessesQuery } from 'graphql/user/GetConsultantOrganizationAccesses.gen'
import { UserProjectMembershipsFieldsFragment } from 'graphql/user/UserProjectMembershipsFields.gen'

import { useUser } from 'providers/UserProvider'

export type TeamWithChildren = TeamFieldsFragment & {
  children: TeamWithChildren[]
}

// Builds a tree of all teams, including all children teams
export function buildAllTeamsTree(teams: TeamFieldsFragment[]): TeamWithChildren[] {
  const teamMap = new Map<string, TeamWithChildren>()
  const rootTeams: TeamWithChildren[] = []

  // First pass: Create all team objects with empty children arrays
  teams.forEach((team) => {
    teamMap.set(team.id, { ...team, children: [] })
  })

  // Second pass: Build the tree structure
  teams.forEach((team) => {
    const currentTeam = teamMap.get(team.id)!

    // Only add as child if parent exists in our array
    if (team.parentTeamId && teamMap.has(team.parentTeamId)) {
      const parentTeam = teamMap.get(team.parentTeamId)!
      parentTeam.children.push(currentTeam)
    } else {
      // If parent doesn't exist in our array, treat as root team
      rootTeams.push(currentTeam)
    }
  })

  // Sort teams at each level by name
  const sortTeams = (teams: TeamWithChildren[]) => {
    teams.sort((a, b) => a.name.localeCompare(b.name))
    teams.forEach((team) => sortTeams(team.children))
  }
  sortTeams(rootTeams)

  return rootTeams
}

export const teamNameComparator: GridComparatorFn<
  NodesType<AccountUsersQuery['users']>['teamMembersList']
> = (param1, param2) => {
  const teamMembers1 = param1 ?? []
  const teamMembers2 = param2 ?? []

  // Helper function to get sorted team names string
  const getTeamNamesString = (teamMembers: typeof teamMembers1) => {
    const teamNames = teamMembers
      .map((tm) => tm.team?.name ?? '')
      .filter(Boolean)
      .sort((a, b) => a.localeCompare(b))

    return teamNames.join(',')
  }

  // Get sorted team names for both rows
  const teamNames1 = getTeamNamesString(teamMembers1)
  const teamNames2 = getTeamNamesString(teamMembers2)

  // Handle empty cases
  if (!teamNames1 && !teamNames2) return 0
  if (!teamNames1) return 1
  if (!teamNames2) return -1

  // Compare the sorted team names strings
  return teamNames1.localeCompare(teamNames2)
}

export const findAllDescendantTeams = (teams: TeamFieldsFragment[], parentTeamId: string) => {
  const descendantIds: string[] = []
  const findDescendants = (teams: TeamFieldsFragment[], currentParentId: string) => {
    const children = teams.filter((team) => team.parentTeamId === currentParentId)
    children.forEach((child) => {
      descendantIds.push(child.id)
      findDescendants(teams, child.id) // Recursively find children of children
    })
  }
  findDescendants(teams, parentTeamId)
  return descendantIds
}

export const useShouldShowTeams = (user: UserRole, isTeamsEnabled: boolean) => {
  const { userId } = useUser()
  const { data: consultantOrgs, loading: loadingConsultantOrgs } =
    useGetConsultantOrganizationAccessesQuery({
      variables: {
        userId,
      },
      skip: !userIsConsultantOnly(user),
    })

  // Always show teams column for super admins or current user's org has teams enabled
  if (isTeamsEnabled || userIsSuperAdmin(user)) return true

  // Show teams column for consultants if they have access to any orgs with teams enabled
  if (userIsConsultantOnly(user)) {
    if (loadingConsultantOrgs) return false
    const consultantOrgsList = consultantOrgs?.consultantOrganizationAccessesList ?? []
    if (consultantOrgsList.length > 0) {
      const orgHasTeamsEnabled = consultantOrgsList.some((coa) => coa.account?.isTeamsEnabled)
      return orgHasTeamsEnabled
    }
  }
  return false
}

// Calculate projects that will lose access
export const findProjectsLosingAccess = (
  projectMemberships: UserProjectMembershipsFieldsFragment[],
  accountTeamsData: TeamFieldsFragment[],
  teamIds: string[],
  teamLeadIds: string[],
  user?: UserRole
) => {
  if (!projectMemberships) return []

  // Consultants, OrgAdmins and SuperAdmins don't have to worry about losing access
  if (user && userIsOrgAdminOrConsultant(user)) return []

  // Calculate all descendant teams that the user will have access to via team lead roles
  const teamsWithLeadAccess = new Set<string>(teamLeadIds)
  teamLeadIds.forEach((teamId) => {
    const descendants = findAllDescendantTeams(accountTeamsData ?? [], teamId)
    descendants.forEach((id) => teamsWithLeadAccess.add(id))
  })

  // Add direct team memberships
  teamIds.forEach((teamId) => teamsWithLeadAccess.add(teamId))

  return projectMemberships.filter((membership) => {
    const projectTeamId = membership.project?.team?.id
    if (!projectTeamId) return false

    // User will lose access if they won't have team membership or team lead access
    return !teamsWithLeadAccess.has(projectTeamId)
  })
}

// Calculate if user is Team Lead of parent team
export const isProjectTeamLead = (
  user?: CurrentUserQuery['currentUser'],
  projectTeam?: TeamFieldsFragment
) => {
  if (!user || !projectTeam) return false

  return user?.teamMembersList?.some((membership) => {
    // Direct team lead of project's team
    if (membership.team?.id === projectTeam.id && membership.isLead) {
      return true
    }

    // Check if user is lead of any parent team
    return membership.team?.id === projectTeam.parentTeamId && membership.isLead
  })
}
