import * as Types from './types';

import { gql } from '@apollo/client';
export type EditRightsRequestFieldsFragment = { __typename?: 'EditRightsRequest', id: string, projectId: string, requesterId: string, status: Types.EditRightsRequestStatus, requestedAt: string, respondedAt: string | null, project: { __typename?: 'Project', name: string, userIsOwner: boolean | null, projectAccessLogs: { __typename?: 'ProjectAccessLogsConnection', nodes: Array<{ __typename?: 'ProjectAccessLog', accessAt: string, userId: string }> } } | null, requester: { __typename?: 'User', fullname: string } | null };

export const EditRightsRequestFieldsFragmentDoc = gql`
    fragment EditRightsRequestFields on EditRightsRequest {
  id
  projectId
  project {
    name
    userIsOwner
    projectAccessLogs {
      nodes {
        accessAt
        userId
      }
    }
  }
  requesterId
  requester {
    fullname
  }
  status
  requestedAt
  respondedAt
}
    `;