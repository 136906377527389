import { Box } from '@mui/material'

import { useProject } from 'components/projects/ProjectProvider'
import { isProjectTeamLead } from 'components/settings/organizations/teams/utils'
import useMembership from 'hooks/useMembership'
import { useUser } from 'providers/UserProvider'
import { colors } from 'theme/colors'
import { internalShadows } from 'theme/shadows'

import ChatTextField from './ChatTextField'

export default function NewCommentField() {
  const { user } = useUser()
  const { canEdit } = useMembership()
  const { project } = useProject()

  const canComment =
    canEdit || isProjectTeamLead(user, project?.team ?? undefined) || user?.isTeamsAdmin

  return (
    <Box
      sx={{
        p: 2,
        top: 0,
        zIndex: 1,
        position: 'sticky',
        bgcolor: 'bg.dark',
        color: colors.bgDarkText,
        boxShadow: internalShadows.popover,
        mb: '-1px', // compensate for double border
        borderBottom: `1px solid ${colors.borderDark}`,
      }}>
      <ChatTextField readOnly={!canComment} placeholder="Make a comment..." />
    </Box>
  )
}
