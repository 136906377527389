import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RespondToEditRightsRequestMutationVariables = Types.Exact<{
  input: Types.RespondToEditRightsRequestInput;
}>;


export type RespondToEditRightsRequestMutation = { __typename?: 'Mutation', respondToEditRightsRequest: { __typename?: 'RespondToEditRightsRequestPayload', status: Types.EditRightsRequestStatus | null } | null };


export const RespondToEditRightsRequestDocument = gql`
    mutation RespondToEditRightsRequest($input: RespondToEditRightsRequestInput!) {
  respondToEditRightsRequest(input: $input) {
    status
  }
}
    `;
export type RespondToEditRightsRequestMutationFn = Apollo.MutationFunction<RespondToEditRightsRequestMutation, RespondToEditRightsRequestMutationVariables>;

/**
 * __useRespondToEditRightsRequestMutation__
 *
 * To run a mutation, you first call `useRespondToEditRightsRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRespondToEditRightsRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [respondToEditRightsRequestMutation, { data, loading, error }] = useRespondToEditRightsRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRespondToEditRightsRequestMutation(baseOptions?: Apollo.MutationHookOptions<RespondToEditRightsRequestMutation, RespondToEditRightsRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RespondToEditRightsRequestMutation, RespondToEditRightsRequestMutationVariables>(RespondToEditRightsRequestDocument, options);
      }
export type RespondToEditRightsRequestMutationHookResult = ReturnType<typeof useRespondToEditRightsRequestMutation>;
export type RespondToEditRightsRequestMutationResult = Apollo.MutationResult<RespondToEditRightsRequestMutation>;
export type RespondToEditRightsRequestMutationOptions = Apollo.BaseMutationOptions<RespondToEditRightsRequestMutation, RespondToEditRightsRequestMutationVariables>;