import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm, WatchObserver } from 'react-hook-form'
import * as yup from 'yup'

import { useUser } from 'providers/UserProvider'
import { ALL_FILTER_VALUE } from 'utils/constants'

export interface SearchType {
  search?: string
  searchAccountIdLabel?: string
  searchAccountId?: string
  searchSuborganizationId?: string
}

type UseAccountSearchFormProps = {
  debouncedHandleSubmit: WatchObserver<SearchType>
  useUserAccountAsDefault?: boolean
  defaultAccountId?: string
  defaultSuborganizationId?: string
}

const schema: yup.SchemaOf<SearchType> = yup.object().shape({
  search: yup.string(),
  searchAccountIdLabel: yup.string(),
  searchAccountId: yup.string().uuid('Account id is required').defined(),
  searchSuborganizationId: yup.string(),
})

const resolver = yupResolver(schema)

export const useAccountSearchForm = ({
  debouncedHandleSubmit,
  useUserAccountAsDefault = true,
  defaultAccountId = '',
  defaultSuborganizationId = '',
}: UseAccountSearchFormProps) => {
  const { user } = useUser()

  let filterAccountId = ALL_FILTER_VALUE
  if (useUserAccountAsDefault) {
    filterAccountId = user?.accountId ?? ''
  } else if (defaultAccountId) filterAccountId = defaultAccountId

  const methods = useForm<Required<SearchType>>({
    mode: 'onSubmit',
    defaultValues: {
      searchAccountIdLabel: '',
      searchAccountId: filterAccountId,
      searchSuborganizationId: defaultSuborganizationId ?? '',
      search: '',
    },
    resolver,
  })

  useEffect(() => {
    const subscription = methods.watch(debouncedHandleSubmit)
    return () => subscription.unsubscribe()
  }, [debouncedHandleSubmit, methods])

  // // Watch for account ID changes and reset suborganization ID when it changes
  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (name === 'searchAccountId') {
        methods.setValue('searchSuborganizationId', '', { shouldValidate: true })
      }
    })
    return () => subscription.unsubscribe()
  }, [methods])

  const accountId = methods.watch('searchAccountId')

  return { methods, accountId }
}

export const createAccountFilter = (accountId?: string | null) => {
  if (accountId) {
    if (accountId === ALL_FILTER_VALUE) {
      return { account: { id: { isNull: false } } }
    }
    return {
      accountId: { equalTo: accountId },
    }
  }
}
